<template>
  <div class="checkin-wrapper">
    <div class="checkin-title">
      <img src="/static/img/checkin/title.svg" alt="title" height="100%" width="100%">
    </div>
    <div class="checkin-subtitle">
      <p>Temukan promo cantik, <br> eksklusif hanya di Sociolla Store!</p>
    </div>
    <div class="checkin-qrcode">
      <div class="qrcode-wrapper">
          <qrcode-vue :value="QRcode" size="436" level="H"></qrcode-vue>
        </div>
    </div>
    <div class="checkin-footer">
      <img src="/static/img/checkin/footer.svg" alt="install" height="122" width="561">
    </div>
  </div>
</template>

<script>
const Config = require('~/config/default.env').default;
export default {
  name: 'CheckIn',
  components: {
    qrcodeVue: () => import('qrcode.vue')
  },
  computed: {
    storeId() {
      if (Cookies.get('store_detail')) {
        const storeDetail = JSON.parse(Cookies.get('store_detail'));
        return storeDetail._id;
      } else {
        return '';
      }
    },
    QRcode() {
      const deeplink = Config.STORE_DEEPLINK;
      return `${deeplink}?store_id=${this.storeId}`;
    }
  }
};
</script>

<style lang="scss" scoped>
  .checkin-wrapper {
    height: 100vh;
    max-width: 1080px;
    padding: 65px;
    margin: auto;
    background-image: url('/static/img/checkin/background.png');
  }
  .checkin-title {
    margin: 73px 0 62px;
  }
  .checkin-subtitle {
    font-family: Lato-Bold;
    font-size: 60px;
    color: #241F20;
    letter-spacing: 0.8px;
    text-align: center;
    line-height: 74px;
    text-align: center;
    margin: 62px 0 80px;
  }
  .checkin-qrcode {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    .qrcode-wrapper {
      padding: 52px;
      background: #FFF;
      border-bottom-left-radius: 40px;
      border-bottom-right-radius: 40px;
    }
    &::before {
      content: '';
      background: url("/static/img/checkin/qrcode-scan-here.svg");
      height: 229px;
      width: 576px;
      display: block;
    }
  }
  .checkin-footer {
    margin-top: 112px;
    text-align: center;
  }
</style>